<template>
  <div>
    <div class="card card-info card-outline">
      <div class="card-header">
        <label for="">
          Cargues
          <span class="badge badge-info">
            {{ detProgramacionSitio.cargues_dec }}
          </span>
          |
        </label>
        <label for="">
          Cargues Ajuste
          <span class="badge badge-info">
            {{ detProgramacionSitio.cargues }}
          </span>
          |
        </label>
        <label for="">
          Ruta corta
          <span class="badge badge-info">
            {{ detProgramacionSitio.cargues_ruta_corta }}
          </span>
          |
        </label>
        <label for="">
          Ruta larga
          <span class="badge badge-info">
            {{ detProgramacionSitio.cargues_ruta_larga }}
          </span>
        </label>
        <div class="btn-group float-right gradient-buttons">
          <button
            type="button"
            class="btn btn-sm bg-navy"
            @click="modalCitas()"
            v-if="
              $parent.form.det_solicitudes_diarias &&
                $parent.form.det_solicitudes_diarias == '' &&
                (saveDetSol || totalLarga + totalCorta > 0)
            "
            :disabled="$parent.form.estado == 2"
          >
            Asignar Citas <i class="fas fa-calendar-check"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm bg-info"
            data-toggle="modal"
            data-target="#modal-form"
            @click="editarCitas()"
            v-if="
              $parent.form.det_solicitudes_diarias &&
                $parent.form.det_solicitudes_diarias.length > 0
            "
            :disabled="$parent.form.estado == 2"
          >
            Editar Citas <i class="fas fa-edit"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm bg-info"
            @click="cargarDatos()"
            v-if="totalLarga + totalCorta == 0"
            :disabled="$parent.form.estado == 2"
          >
            Cargar Propuesta <i class="fas fa-spinner"></i>
          </button>
          <button
            type="button"
            class="btn btn-sm bg-success"
            @click="cargarDatosFinal()"
            :disabled="$parent.form.estado == 2"
          >
            Cargar Rutas <i class="fas fa-file-upload"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-toggle="modal"
            data-target="#Modal_import_solicitud"
            @click="
              llenarModalImport(
                $parent.form,
                detProgramacionSitio.cargues_ruta_larga,
                detProgramacionSitio.cargues_ruta_corta,
                detProgramacionSitio.cargues
              )
            "
            v-if="
              $store.getters.can(
                'hidrocarburos.solicitudes.subirSolicitudManual'
              ) &&
                $parent.form.estado == 1 &&
                $parent.form.solicitud_manual == 1
            "
          >
            Solicitud Manual <i class="fas fa-share-square"></i>
          </button>
        </div>
      </div>

      <div class="card-body table-responsive p-0">
        <div class="row">
          <div class="col-md-12">
            <!-- <nav>
              <ul
                data-widget="treeview"
                role="menu"
                data-accordion="false"
                class="nav nav-pills nav-sidebar flex-column nav-flat nav-child-indent"
                v-for="detSolicitudEmpresa in detSolicitudEmpresas"
                :key="detSolicitudEmpresa.id"
              >
                <li class="nav-item has-treeview">
                  <a href="#" class="nav-link text-dark">
                    <i class="nav-icon fas fa-circle"></i>
                    <p style="font-size:15px">
                      {{ detSolicitudEmpresa.empresa.razon_social }}
                    </p>
                  </a>
                </li>
              </ul>
            </nav> -->

            <table
              class="table table-sm table-bordered table-hover text-center"
            >
              <thead class="table-dark">
                <tr>
                  <th>Empresa</th>
                  <th rowspan="2">Calificacion</th>
                  <th rowspan="2">Ajuste</th>
                  <th rowspan="2">Guias</th>
                  <th colspan="2">Cargues</th>
                  <th colspan="2">Adicionales</th>
                  <th colspan="2">Disminucion</th>
                  <th rowspan="2">Rechazo adicional</th>
                  <th rowspan="2">Cargues</th>
                  <th rowspan="2">Adicionales</th>
                  <th rowspan="2">Disminucion</th>
                  <th rowspan="2">Total</th>
                  <th rowspan="2">Ruta Corta</th>
                  <th rowspan="2">Ruta Larga</th>
                  <th rowspan="2"></th>
                </tr>

                <tr>
                  <th>Mes</th>
                  <th>Dia</th>
                  <th>Mes</th>
                  <th>Dia</th>
                  <th>Mes</th>
                  <th>Dia</th>
                  <th>Mes</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="detSolicitudEmpresa in detSolicitudEmpresas"
                  :key="detSolicitudEmpresa.id"
                  :class="{ editing: detSolicitudEmpresa == editedItem }"
                  v-cloak
                >
                  <td
                    :class="
                      detSolicitudEmpresa.tipo_empresa == 2
                        ? 'table-warning'
                        : ''
                    "
                  >
                    {{ detSolicitudEmpresa.empresa.razon_social }}
                  </td>
                  <td v-if="detSolicitudEmpresa.data_nomi">
                    {{ detSolicitudEmpresa.data_nomi.calificacion }}
                  </td>
                  <td v-else></td>
                  <td
                    v-if="detSolicitudEmpresa.data_nomi"
                    :class="
                      parseFloat(detSolicitudEmpresa.data_nomi.ajuste_acum) >=
                        parseFloat(
                          detSolicitudEmpresa.data_nomi.adicional_mes
                        ) ||
                      parseFloat(detSolicitudEmpresa.data_nomi.ajuste_acum) <=
                        -parseFloat(
                          detSolicitudEmpresa.data_nomi.disminucion_mes
                        )
                        ? 'table-warning'
                        : ''
                    "
                  >
                    {{ detSolicitudEmpresa.data_nomi.ajuste_acum }}
                  </td>
                  <td v-else></td>
                  <td
                    :class="
                      detSolicitudEmpresa.data_nomi &&
                      parseFloat(detSolicitudEmpresa.cant_guias) >=
                        parseFloat(detSolicitudEmpresa.data_nomi.cargues_mes) +
                          parseFloat(
                            detSolicitudEmpresa.data_nomi.adicional_mes
                          )
                        ? 'table-danger'
                        : 'table-success'
                    "
                  >
                    {{ detSolicitudEmpresa.cant_guias }}
                  </td>
                  <td v-if="detSolicitudEmpresa.data_nomi">
                    {{ detSolicitudEmpresa.data_nomi.cargues_dia }}
                  </td>
                  <td v-else></td>
                  <td v-if="detSolicitudEmpresa.data_nomi">
                    {{ detSolicitudEmpresa.data_nomi.cargues_mes }}
                  </td>
                  <td v-else></td>
                  <td v-if="detSolicitudEmpresa.data_nomi">
                    {{ detSolicitudEmpresa.data_nomi.adicional_dia }}
                  </td>
                  <td v-else></td>
                  <td v-if="detSolicitudEmpresa.data_nomi">
                    {{ detSolicitudEmpresa.data_nomi.adicional_mes }}
                  </td>
                  <td v-else></td>
                  <td v-if="detSolicitudEmpresa.data_nomi">
                    {{ detSolicitudEmpresa.data_nomi.disminucion_dia }}
                  </td>
                  <td v-else></td>
                  <td v-if="detSolicitudEmpresa.data_nomi">
                    {{ detSolicitudEmpresa.data_nomi.disminucion_mes }}
                  </td>
                  <td v-else></td>
                  <td
                    v-if="
                      detSolicitudEmpresa.rechaza_add == 1 ||
                        detSolicitudEmpresa.rechaza_add == true
                    "
                  >
                    <input
                      type="checkbox"
                      id="rechazo_add"
                      v-model="detSolicitudEmpresa.rechaza_add"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.solicitudes.cambiarRechazo'
                        )
                      "
                      :disabled="$parent.val_fecha == false"
                      @input="
                        cambiarRechazo(
                          detSolicitudEmpresa.id,
                          detSolicitudEmpresa.rechaza_add
                        )
                      "
                    />
                  </td>
                  <td v-else></td>
                  <td>{{ detSolicitudEmpresa.cargues }}</td>
                  <td>{{ detSolicitudEmpresa.cargues_adicional }}</td>
                  <td>{{ detSolicitudEmpresa.cargues_disminucion }}</td>
                  <td>
                    <span
                      class="badge"
                      :class="
                        parseInt(detSolicitudEmpresa.cargues) +
                          parseInt(detSolicitudEmpresa.cargues_adicional) !==
                        detSolicitudEmpresa.ruta_corta +
                          detSolicitudEmpresa.ruta_larga
                          ? 'badge-danger'
                          : 'badge-success'
                      "
                    >
                      {{
                        parseInt(detSolicitudEmpresa.cargues) +
                          parseInt(detSolicitudEmpresa.cargues_adicional)
                      }}
                    </span>
                  </td>
                  <td>
                    <div
                      class="view"
                      style="font-size: 14px"
                      :class="
                        detSolicitudEmpresa.ruta_corta == 0 ? 'text-danger' : ''
                      "
                      v-if="!editedItem"
                    >
                      {{ detSolicitudEmpresa.ruta_corta }}
                    </div>

                    <div class="edit" v-else>
                      <input
                        type="number"
                        v-model="detSolicitudEmpresa.ruta_corta"
                        required
                        v-if="editedItem.id == detSolicitudEmpresa.id"
                      />
                    </div>
                  </td>
                  <td>
                    <div
                      class="view"
                      style="font-size: 14px"
                      :class="
                        detSolicitudEmpresa.ruta_larga == 0 ? 'text-danger' : ''
                      "
                      v-if="!editedItem"
                    >
                      {{ detSolicitudEmpresa.ruta_larga }}
                    </div>
                    <div class="edit" v-else>
                      <input
                        type="number"
                        v-model="detSolicitudEmpresa.ruta_larga"
                        required
                        v-if="editedItem.id == detSolicitudEmpresa.id"
                      />
                    </div>
                  </td>
                  <td style="width: 70px">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary py-0"
                        data-toggle="modal"
                        data-target="#modal-form-view"
                        @click="getModal(detSolicitudEmpresa)"
                        :disabled="$parent.form.estado == 2"
                      >
                        <i class="fas fa-clipboard-check"></i>
                      </button>
                      <div class="view" v-if="!editedItem">
                        <button
                          type="button"
                          class="btn btn-sm btn-dark"
                          @click="editData(detSolicitudEmpresa)"
                          :disabled="$parent.form.estado == 2"
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                      </div>
                      <div class="edit" v-if="editedItem">
                        <button
                          type="button"
                          class="btn btn-sm bg-navy"
                          @click="saveEditData(detSolicitudEmpresa)"
                          :disabled="$parent.form.estado == 2"
                        >
                          <i class="fas fa-save"></i>
                        </button>
                      </div>
                      <div class="edit" v-if="editedItem">
                        <button
                          class="btn btn-sm btn-danger"
                          @click="editedItem = null"
                        >
                          <i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot class="table-dark">
                <tr>
                  <td colspan="15"><strong>Total</strong></td>
                  <td>
                    <strong>{{ totalCorta }}</strong>
                  </td>
                  <td>
                    <strong>{{ totalLarga }}</strong>
                  </td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modal-form"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left p-2">
            <h5 class="modal-title text-white">Asignación Citas</h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tipo Ruta</th>
                      <th>Empresa</th>
                      <th>Cita</th>
                      <th>Cargues</th>
                      <th
                        v-if="
                          $parent.form.det_solicitudes_diarias &&
                            $parent.form.det_solicitudes_diarias == ''
                        "
                      >
                        Tipo Vehiculo
                        <select
                          class="form-control form-control-sm"
                          v-model="slctAllTipoVehi"
                          @change="selectAllTipoVehi()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="op in listas.tipo_vehiculo"
                            :key="op.id"
                            :value="op.id"
                          >
                            {{ op.nombre }}
                          </option>
                        </select>
                      </th>
                      <th v-else>Tipo Vehiculo</th>
                      <th
                        v-if="
                          $parent.form.det_solicitudes_diarias &&
                            $parent.form.det_solicitudes_diarias == ''
                        "
                      >
                        Tipo Producto
                        <select
                          class="form-control form-control-sm"
                          v-model="slctAllTipoProd"
                          @change="selectAllTipoProd()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="op in listas.tipo_producto"
                            :key="op.id"
                            :value="op.id"
                          >
                            {{ op.nombre }}
                          </option>
                        </select>
                      </th>
                      <th v-else>Tipo Producto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, indice) in empresasCitas" :key="item.id">
                      <td>{{ indice + 1 }}</td>
                      <td v-if="item.tipo_ruta == 1">Ruta Corta</td>
                      <td v-else>Ruta Larga</td>
                      <td>{{ item.nombreEmpresa }}</td>
                      <td>
                        <!-- <input
                          type="time"
                          v-model="empresasCitas[indice].cita"
                          class="form-control form-control-sm"
                          required
                        /> -->
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="empresasCitas[indice].cita"
                          v-mask="{
                            mask: '99:99',
                            hourFormat: '24',
                          }"
                          @change="
                            validate_hora_cita(empresasCitas[indice].cita)
                          "
                        />
                      </td>
                      <td>{{ item.cargues }}</td>
                      <td
                        v-if="
                          $parent.form.det_solicitudes_diarias &&
                            $parent.form.det_solicitudes_diarias == ''
                        "
                      >
                        <div class="col-md-12">
                          <div class="form-group">
                            <select
                              class="form-control form-control-sm"
                              :id="'tipo_vehi' + indice + 1"
                              v-model="empresasCitas[indice].tipo_vehiculo"
                              :class="
                                empresasCitas[indice].tipo_vehiculo == null ||
                                empresasCitas[indice].tipo_vehiculo == ''
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="op in listas.tipo_vehiculo"
                                :key="op.id"
                                :value="op.id"
                              >
                                {{ op.nombre }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td v-else>
                        {{ item.tipo_vehiculo.nombre }}
                      </td>
                      <td
                        v-if="
                          $parent.form.det_solicitudes_diarias &&
                            $parent.form.det_solicitudes_diarias == ''
                        "
                      >
                        <div class="col-md-12">
                          <div class="form-group">
                            <select
                              class="form-control form-control-sm"
                              :id="'tipo_prod' + indice + 1"
                              v-model="empresasCitas[indice].tipo_producto"
                              :class="
                                empresasCitas[indice].tipo_producto == null ||
                                empresasCitas[indice].tipo_producto == ''
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                            >
                              <option value="">Seleccione...</option>
                              <option
                                v-for="op in listas.tipo_producto"
                                :key="op.id"
                                :value="op.id"
                              >
                                {{ op.nombre }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </td>
                      <td v-else>
                        {{ item.producto.nombre }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="
                btn btn-default btn-lg
                bg-frontera-top-left
                text-white
                mt-2
                m-2
                float-right
              "
              @click="saveDetSolicitudTemp()"
              v-show="
                !empresasCitas.some(
                  (cita) =>
                    cita.tipo_producto == null || cita.tipo_producto == ''
                ) &&
                  !empresasCitas.some(
                    (cita) =>
                      cita.tipo_vehiculo == null || cita.tipo_vehiculo == ''
                  )
              "
              v-if="
                $parent.form.det_solicitudes_diarias &&
                  $parent.form.det_solicitudes_diarias == ''
              "
            >
              <i class="fas fa-save"></i>
            </button>
            <button
              class="
                btn btn-default btn-lg
                bg-frontera-top-left
                text-white
                mt-2
                m-2
                float-right
              "
              @click="guardarCita()"
              v-else
            >
              <i class="fas fa-edit"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal-form-view">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left p-2">
            <h5 class="modal-title text-white">
              {{
                registroActual !== null
                  ? registroActual.empresa.razon_social
                  : "Seleccione empresa"
              }}
            </h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-striped table-sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tipo Ruta</th>
                      <th>Tipo Producto</th>
                      <th>Tipo Vehículo</th>
                      <th>Cargues</th>
                      <th>Cita</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(det, indice) in detSolicitud" :key="det.id">
                      <td>{{ indice + 1 }}</td>
                      <td v-if="det.tipo_ruta == 1">Ruta Corta</td>
                      <td v-else>Ruta Larga</td>
                      <td>{{ det.producto.nombre }}</td>
                      <td>{{ det.tipo_vehiculo.nombre }}</td>
                      <td>{{ det.cargues }}</td>
                      <td>
                        <span class="badge badge-info">
                          {{ det.cita }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SolicitudManual ref="solicitudManual" />
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import $ from "jquery";
import SolicitudManual from "./SolicitudManual.vue";

export default {
  name: "SolicitudEmpresas",
  components: {
    SolicitudManual,
  },

  data() {
    return {
      form: {},
      detSolicitudEmpresas: [],
      detSolicitud: [],
      detProgramacionSitio: {},
      sitio: {},
      registroActual: null,
      formCarguesCorta: 0,
      formCarguesLarga: 0,
      listas: {
        tipo_ruta: [],
        tipo_producto: [],
        tipo_vehiculo: [],
      },
      formDet: {
        tipo_producto: [],
        tipo_vehiculo: [],
      },
      limites: {
        carges: 0,
        carges_corta: 0,
        carges_larga: 0,
      },
      editedItem: null,
      sumaRutas: 0,
      totalCorta: 0,
      totalLarga: 0,
      totalAdd: 0,
      tipoRuta: 0,
      empresasCitas: new Array(),
      editedIndiceCita: null,
      slctAllTipoVehi: null,
      slctAllTipoProd: null,
      saveDetSol: false,
    };
  },
  validations: {
    formDet: {
      tipo_producto: {
        required,
      },
      tipo_vehiculo: {
        required,
      },
    },
  },
  methods: {
    getTipoRuta() {
      axios.get("/api/lista/48").then((response) => {
        this.listas.tipo_ruta = response.data;
      });
    },

    getTipoProductos() {
      axios.get("/api/admin/productos/lista").then((response) => {
        this.listas.tipo_producto = response.data;
      });
    },

    getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listas.tipo_vehiculo = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    editData(item) {
      this.editedItem = item;
    },

    editDataCitas(indice) {
      this.editedIndiceCita = indice;
    },

    validate_hora_cita(hora_cita) {
      if (hora_cita) {
        const regex = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/;
        const matches = regex.exec(hora_cita);

        if (matches != null) {
          return true && matches[0];
        } else {
          this.$swal({
            icon: "error",
            title: "La Hora que ingreso es invalida...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          return false && null;
        }
      }
    },

    cambiarRechazo(id, valor) {
      if (valor == true || valor == 1) {
        this.$swal({
          title: "Está seguro de activar esta empresa para adicionales?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Cambiar!",
        }).then((result) => {
          this.$parent.cargando = true;
          if (result.value) {
            axios({
              method: "POST",
              url: "/api/hidrocarburos/solicitudes/cambiarRechazo",
              data: { id: id, valor: valor },
            })
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Se realizo el cambio exitosamente ...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.$parent.cargando = false;
              })
              .catch(function(error) {
                this.$parent.cargando = false;
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error" + error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      } else {
        this.$swal({
          title: "Está seguro de desactivar esta empresa para adicionales ?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Cambiar!",
        }).then((result) => {
          this.$parent.cargando = true;
          if (result.value) {
            axios({
              method: "POST",
              url: "/api/hidrocarburos/solicitudes/cambiarRechazo",
              data: { id: id, valor: valor },
            })
              .then(() => {
                this.$swal({
                  icon: "success",
                  title: "Se guardo exitosamente ...",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
                this.$parent.cargando = false;
              })
              .catch(function(error) {
                this.$parent.cargando = false;

                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error" + error,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              });
          }
        });
      }
    },

    saveEditDataCitas(item) {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;

      axios({
        method: "POST",
        url: "/api/hidrocarburos/solicitudes/editCitas",
        data: this.empresasCitas,
      })
        .then(() => {
          this.formDet = {};
          this.empresasCitas = [];
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente ...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
          this.saveDetSol = true;
        })
        .catch(function(error) {
          this.$parent.cargando = false;

          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          this.saveDetSol = true;
        });
    },

    sumatoriaRutas() {
      this.totalCorta = 0;
      this.totalLarga = 0;
      this.totalAdd = 0;
      for (let index = 0; index < this.detSolicitudEmpresas.length; index++) {
        this.totalCorta += parseInt(
          this.detSolicitudEmpresas[index].ruta_corta
        );
        this.totalLarga += parseInt(
          this.detSolicitudEmpresas[index].ruta_larga
        );
        this.totalAdd += parseInt(
          this.detSolicitudEmpresas[index].cargues_adicional
        );
      }
    },

    async saveEditData(item) {
      await this.sumatoriaRutas();
      this.sumaRutas = parseInt(item.ruta_corta) + parseInt(item.ruta_larga);
      if (
        this.sumaRutas >
        parseInt(item.cargues) + parseInt(item.cargues_adicional)
      ) {
        this.$swal({
          text:
            "La sumatoria de ruta corta y ruta larga no puede ser mayor al numero de cargues para la empresa",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.editedItem = null;
        await this.getDetSolicitudEmpresas();
      } else if (
        this.totalCorta > this.detProgramacionSitio.cargues_ruta_corta
      ) {
        this.$swal({
          text:
            "La sumatoria de ruta corta de todas las empresas no puede ser mayor al valor de la parte superior",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.editedItem = null;
        await this.getDetSolicitudEmpresas();
      } else if (
        this.totalLarga > this.detProgramacionSitio.cargues_ruta_larga
      ) {
        this.$swal({
          text:
            "La sumatoria de ruta larga de todas las empresas no puede ser mayor al valor de la parte superior",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
        this.editedItem = null;
        await this.getDetSolicitudEmpresas();
      } else {
        await this.saveRutas(item);
      }
    },

    saveRutas(item) {
      this.$parent.cargando = true;
      axios({
        method: "PUT",
        url: "/api/hidrocarburos/solicitudes/editRutas",
        data: item,
      })
        .then(async () => {
          this.$parent.cargando = false;
          this.editedItem = null;
          await this.getDetSolicitudEmpresas();
          this.$swal({
            icon: "success",
            title: "La solicitud se edito exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$parent.cargando = false;
          let res = Object.assign({}, e);
          this.$swal({
            icon: "error",
            title:
              "Ocurrio un error, vuelva a intentarlo..." +
              res.response.data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    editarCitas() {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;
      axios
        .get(
          "/api/hidrocarburos/solicitudes/getDetSolicitudTemp/" + idSolicitud,
          {}
        )
        .then((response) => {
          this.empresasCitas = response.data;
          this.$parent.cargando = false;
        })
        .catch(function(error) {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },

    cargarDatos() {
      let cantidad_ruta_corta = this.detProgramacionSitio.cargues_ruta_corta;
      let cantidad_ruta_larga = this.detProgramacionSitio.cargues_ruta_larga;
      let ruta_corta_asig = 0;
      let ruta_larga_asig = 0;
      let cargues_totales = 0;
      for (let j = 0; j < this.detSolicitudEmpresas.length; j++) {
        cargues_totales += this.detSolicitudEmpresas[j].cargues;
      }
      for (let i = 0; i < this.detSolicitudEmpresas.length; i++) {
        if (
          parseInt(this.detSolicitudEmpresas[i].cargues) +
            parseInt(this.detSolicitudEmpresas[i].cargues_adicional) >
          parseInt(this.detSolicitudEmpresas[i].ruta_corta) +
            parseInt(this.detSolicitudEmpresas[i].ruta_larga)
        ) {
          if (cantidad_ruta_corta > 0) {
            if (
              parseInt(this.detSolicitudEmpresas[i].cargues) +
                parseInt(this.detSolicitudEmpresas[i].cargues_adicional) >
              parseInt(this.detSolicitudEmpresas[i].ruta_corta) +
                parseInt(this.detSolicitudEmpresas[i].ruta_larga)
            ) {
              this.detSolicitudEmpresas[i].ruta_corta++;
              ruta_corta_asig++;
              cantidad_ruta_corta--;
            }
          } else if (cantidad_ruta_larga > 0) {
            if (
              parseInt(this.detSolicitudEmpresas[i].cargues) +
                parseInt(this.detSolicitudEmpresas[i].cargues_adicional) >
              parseInt(this.detSolicitudEmpresas[i].ruta_corta) +
                parseInt(this.detSolicitudEmpresas[i].ruta_larga)
            ) {
              this.detSolicitudEmpresas[i].ruta_larga++;
              ruta_larga_asig++;
              cantidad_ruta_larga--;
            }
          } else {
            continue;
          }
        }
        if (this.detSolicitudEmpresas.length - 1 == i) {
          if (ruta_corta_asig + ruta_larga_asig < cargues_totales) {
            i = -1;
          }
        }
      }
      this.sumatoriaRutas();
    },

    cargarDatosFinal() {
      axios({
        method: "POST",
        url: "/api/hidrocarburos/solicitudes/cargarDatos",
        data: this.detSolicitudEmpresas,
      })
        .then(async () => {
          this.$parent.cargando = false;

          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente ...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          await this.getDetSolicitudEmpresas();
          this.saveDetSol = true;
        })
        .catch(async function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          await this.getDetSolicitudEmpresas();
          this.$parent.cargando = false;
          this.saveDetSol = true;
        });
    },

    saveDetSolicitudTemp() {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;
      axios({
        method: "POST",
        url: "/api/hidrocarburos/solicitudes/saveDetSolicitudTemp",
        data: [this.empresasCitas, idSolicitud],
      })
        .then(() => {
          this.$refs.closeModal.click();
          this.empresasCitas = [];
          this.$parent.$refs.compSolicitudDiariaCalendar.getEvents();
          this.$parent.show();
          this.$swal({
            icon: "success",
            title: "Se guardo exitosamente ...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async guardarCita() {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;
      await axios({
        method: "PUT",
        url: "/api/hidrocarburos/solicitudes/editCitas",
        data: [this.empresasCitas, idSolicitud],
      })
        .then(() => {
          this.$refs.closeModal.click();
          this.empresasCitas = [];
          this.$parent.show();

          this.$swal({
            icon: "success",
            title: "Se edito la hora de la cita exitosamente ...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        })
        .catch((e) => {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    limpiarModal() {
      this.empresasCitas = [];
    },

    modalCitas() {
      /* if (
        this.totalCorta == this.limites.carges_corta &&
        this.totalLarga == this.limites.carges_larga
      ) { */
      window.$("#modal-form").modal("show");
      this.asignarCitas();
      /* } else {
        this.$swal({
          text: "La sumatoria de ruta corta y ruta larga de todas las empresas no es igual al valor superior..",
          icon: "warning",
          confirmButtonText: "Aceptar!",
        });
      } */
    },

    asignarCitas() {
      let numPosib = this.detSolicitudEmpresas.length - 1;
      let aleatorio = Math.random() * (numPosib + 1);
      aleatorio = Math.floor(aleatorio);
      let inicio = this.$parent.sitio.hora_inicial;
      let empresaIncrement = aleatorio;
      let hi = moment(inicio, "H:mm:ss");
      let horaIncrement;
      let cantCargues = 0;
      let rutaCorta = 0;
      let rutaLarga = 0;
      let rutaCortaEmpresa = 0;
      let rutaLargaEmpresa = 0;
      let tipoRuta;
      let cita;
      let totRutacortaAsig = 0;
      let toLargataAsig = 0;

      let EmpresaCargues = new Array();
      for (let j = 0; j < this.detSolicitudEmpresas.length; j++) {
        EmpresaCargues[j] = {
          ruta_corta: this.detSolicitudEmpresas[j].ruta_corta,
          ruta_larga: this.detSolicitudEmpresas[j].ruta_larga,
        };
        totRutacortaAsig += this.detSolicitudEmpresas[j].ruta_corta;
        toLargataAsig += this.detSolicitudEmpresas[j].ruta_larga;
      }
      let Z = 0;
      let totalAsing =
        parseInt(this.totalCorta) +
        parseInt(this.totalLarga) -
        parseInt(this.totalAdd);
      for (let i = 0; i < totalAsing; i++) {
        Z++;
        if (rutaCorta < this.totalCorta) {
          tipoRuta = 1;
          rutaCorta++;
          if (EmpresaCargues[empresaIncrement].ruta_corta > 0) {
            cita = {
              solicitud_diaria_id: this.$parent.id,
              tipo_ruta: tipoRuta,
              empresa_id: this.detSolicitudEmpresas[empresaIncrement]
                .empresa_id,
              nombreEmpresa: this.detSolicitudEmpresas[empresaIncrement].empresa
                .razon_social,
              cita: hi.format("HH:mm:ss"),
              cargues: 1,
              tipo_vehiculo: null,
              tipo_producto: null,
            };
            rutaCortaEmpresa++;
            EmpresaCargues[empresaIncrement].ruta_corta--;
            this.empresasCitas.push(cita);
          } else {
            i--;
            cantCargues--;
            rutaCorta--;
          }
        } else if (rutaLarga < this.totalLarga) {
          tipoRuta = 2;
          rutaLarga++;
          if (EmpresaCargues[empresaIncrement].ruta_larga > 0) {
            cita = {
              solicitud_diaria_id: this.$parent.id,
              tipo_ruta: tipoRuta,
              empresa_id: this.detSolicitudEmpresas[empresaIncrement]
                .empresa_id,
              nombreEmpresa: this.detSolicitudEmpresas[empresaIncrement].empresa
                .razon_social,
              cita: hi.format("HH:mm:ss"),
              cargues: 1,
              tipo_vehiculo: null,
              tipo_producto: null,
            };
            rutaLargaEmpresa++;
            EmpresaCargues[empresaIncrement].ruta_larga--;
            this.empresasCitas.push(cita);
          } else {
            i--;
            cantCargues--;
            rutaLarga--;
          }
        }
        cantCargues++;
        if (this.$parent.sitio.cargues_hora == cantCargues) {
          horaIncrement = moment(hi)
            .add(1, "hours")
            .format("H:mm:ss");
          hi = moment(horaIncrement, "H:mm:ss");
          cantCargues = 0;
        }
        if (
          (rutaCorta === this.totalCorta && rutaLarga === this.totalLarga) ||
          (rutaCorta === totRutacortaAsig && rutaLarga === toLargataAsig) ||
          Z > 500
        ) {
          break;
        }
        if (empresaIncrement >= this.detSolicitudEmpresas.length - 1) {
          empresaIncrement = 0;
          continue;
        }
        empresaIncrement++;
      }
    },

    async calcLimites() {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;
      await axios
        .get("/api/hidrocarburos/solicitudes/detSolicitud/" + idSolicitud, {})
        .then((response) => {
          let detSolicitud = response.data;
          let corta = 0;
          let larga = 0;
          for (let i = 0; i < detSolicitud.length; i++) {
            const element = detSolicitud[i];
            if (element.tipo_ruta == 1) {
              corta += element.cargues;
            } else if (element.tipo_ruta == 2) {
              larga += element.cargues;
            }
          }

          this.limites.carges_corta =
            this.detProgramacionSitio.cargues_ruta_corta - corta;
          this.limites.carges_larga =
            this.detProgramacionSitio.cargues_ruta_larga - larga;

          this.$parent.cargando = false;
        })
        .catch(function(error) {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getDetSolicitudEmpresas() {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;
      axios
        .get("/api/hidrocarburos/solicitudes/detEmpresas/" + idSolicitud, {})
        .then(async (response) => {
          this.detSolicitudEmpresas = response.data;
          await this.sumatoriaRutas();
          this.$parent.cargando = false;
          await this.calcLimites();

          let cantidades_empresas = {
            totalCorta: this.totalCorta,
            totalLarga: this.totalLarga,
          };

          this.$parent.cantidades_empresas = cantidades_empresas;
        })
        .catch(function(error) {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getModal(data) {
      this.registroActual = data;
      this.getDetSolicitud();
    },

    getDetSolicitud() {
      this.$parent.cargando = true;
      let idSolicitud = this.$parent.form.id;
      axios
        .get(
          "/api/hidrocarburos/solicitudes/getDetSolicitudTemp/" + idSolicitud,
          {
            params: {
              empresa_id: this.registroActual.empresa_id,
            },
          }
        )
        .then((response) => {
          this.detSolicitud = response.data;
          this.$parent.cargando = false;
        })
        .catch(function(error) {
          this.$parent.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    selectAllTipoVehi() {
      for (let i = 0; i < this.empresasCitas.length; i++)
        this.empresasCitas[i].tipo_vehiculo = this.slctAllTipoVehi;
    },

    selectAllTipoProd() {
      for (let i = 0; i < this.empresasCitas.length; i++)
        this.empresasCitas[i].tipo_producto = this.slctAllTipoProd;
    },

    llenarModalImport(
      solicitud,
      cargues_ruta_larga,
      cargues_ruta_corta,
      cargues
    ) {
      this.$refs.solicitudManual.llenarModalImport(
        solicitud,
        cargues_ruta_larga,
        cargues_ruta_corta,
        cargues
      );
    },

    async init() {
      this.detProgramacionSitio = this.$parent.form.det_programacion_sitio;
      await this.getDetSolicitudEmpresas();
      await this.getTipoRuta();
      await this.getTipoProductos();
      await this.getTipoVehiculo();
    },
  },

  mounted() {
    this.init();
  },
};
</script>
